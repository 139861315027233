import { render, staticRenderFns } from "./nftmining.vue?vue&type=template&id=8b75fef6&scoped=true"
import script from "./nftmining.vue?vue&type=script&lang=js"
export * from "./nftmining.vue?vue&type=script&lang=js"
import style0 from "./nftmining.vue?vue&type=style&index=0&id=8b75fef6&prod&scoped=true&lang=css"
import style1 from "./nftmining.vue?vue&type=style&index=1&id=8b75fef6&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_cache-loader@4.1.0_webpack@4.47.0__css-loader@3.6_f5x577lqpovwgyzvdlsnhamzli/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b75fef6",
  null
  
)

export default component.exports