<template>
  <div class="vault_wrap">
    <Spin class="global_loading" tip="loading" :spinning="spinStatus" size="large">
      <div class="battle_wrap">
        <div class="vault_top_nav vault_top_nav_all">
          <ul class="vault_nav">
            <li v-for="(item,index) in navArr" :key="index" @click="handleContChange(index)" class="valut_nav_item" :class="currentIndex == index ? 'active' : ''">{{$t('l.'+item)}}</li>
            <div id="shadowBox" :style="{left: (currentIndex*50)+'%'}"></div>
          </ul>
          <div class="copy_link">
            <span class="link_title">{{$t('l.invite_link')}}</span>
            <div class="link_wrap">
              <span class="link_val">{{currentHref+'#/doystore?address='+getActiveAccount}}</span>
              <img @click="handleCopyLink" class="copy_icon" src="../assets/invite_icon.svg" alt="">
            </div>
          </div>
        </div>
        <div class="vault_top_nav vault_top_nav_type">
          <ul class="vault_nav">
            <li v-for="(item,index) in (currentIndex == 0 ? navArr_type1 : navArr_type2)" :key="index" @click="handleContChangeType(index)" class="valut_nav_item" :class="currentTypeIndex == index ? 'active' : ''">{{$t('l.'+item)}}</li>
            <div id="shadowBox" :style="{left: (currentTypeIndex*50)+'%'}"></div>
          </ul>
          <div class="operate_btn_wrap">
            <div class="edit_btn create_btn" v-if="currentIndex == 0" @click="handleShowCreateSaleOrderModal">{{$t('l.sale_doy_btn')}}</div>
            <div class="edit_btn create_btn" v-if="currentIndex == 1" @click="handleShowCreateBuyOrderModal">{{$t('l.buy_doy_btn')}}</div>
          </div>
        </div>
        <div class="doy_price_wrap">
          <p class="doy_price_title">{{$t('l.doy_price_title')}}</p>
          <p class="doy_price_val">{{recentPrice}} {{$t('l.token3')}}</p>
        </div>
        <ul class="fight_title">
          <li class="fight_title_item">{{$t('l.doy_title_1')}}</li>
          <li class="fight_title_item">{{$t('l.doy_title_2')}}</li>
          <li class="fight_title_item">{{$t('l.doy_title_3')}}</li>
          <li class="fight_title_item">{{$t('l.doy_title_4')}}</li>
        </ul>
        <ul class="card_wrap">
          <li class="card_item" v-for="(item,index) in dataLists" :key="index">
            <p class="card_item_cont">{{item.amount}}</p>
            <p class="card_item_cont">{{item.price}}</p>
            <p class="card_item_cont">{{item.totalPrice}}</p>
            <div class="btn_type_wrap" v-if="currentIndex == 0">
              <div class="edit_btn fight_btn" v-if="currentTypeIndex == 0 && hasApproved" @click="handleShowBuyOrderModal(index)">{{$t('l.doy_buy')}}</div>
              <div class="edit_btn fight_btn" v-if="currentTypeIndex == 0 && !hasApproved" @click="handleApprovedFor">{{$t('l.approve')}}</div>
              <div class="edit_btn fight_btn fight_ghost_btn" v-if="currentTypeIndex == 1" @click="handleCancelOrder(item.orderId)">{{$t('l.cancel_doy_sale')}}</div>
            </div>
            <div class="btn_type_wrap" v-if="currentIndex == 1">
              <div class="edit_btn fight_btn" v-if="currentTypeIndex == 0 && hasApproved" @click="handleShowSaleOrderModal(index)">{{$t('l.doy_sale')}}</div>
              <div class="edit_btn fight_btn" v-if="currentTypeIndex == 0 && !hasApproved" @click="handleApprovedFor">{{$t('l.approve')}}</div>
              <div class="edit_btn fight_btn fight_ghost_btn" v-if="currentTypeIndex == 1" @click="handleCancelOrder(item.orderId)">{{$t('l.cancel_doy_buy')}}</div>
            </div>
          </li>
        </ul>
        <div class="operate">
          <div class="jump_wrap">
            <input class="jump_ipt" v-model="targetJumpPage" /><span class="line">/</span><span class="total_page">{{pageTotal == 0 ? 1 : pageTotal}}</span>
            <Button class="jump_btn" @click="handleJumpToTargetPage">{{$t('l.go')}}</Button>
          </div>
          <div class="operate_btn_wrap page_operate_btn_wrap">
            <Button class="paga_btn" type="primary" @click="handlePageChange('prev')">{{$t('l.prev')}}</Button>
            <Button class="paga_btn" type="primary" @click="handlePageChange('next')">{{$t('l.next')}}</Button>
          </div>
        </div>
        <!-- <div class="fight_info">
          <div class="fight_info_title">{{$t('l.fight_info_title')}}</div>
          <div class="fight_info_desc">{{$t('l.fight_info_desc')}}</div>
        </div> -->
      </div>
      <Modal class="battle_modal" v-model="showCreateSaleOrderModal" :footer="null" :width="!$store.state.accounts.isMobile ? '380px' : '88%'" @cancel="handleCreateSaleOrderModalCancel" :centered="true" :destroyOnClose="true" :zIndex="999">
        <div class="pools__dialog-inner pools__dialog-withdraw">
          <div class="pools__dialog__header">{{$t('l.sale_doy_btn')}}</div>
          <ul class="doy_sale_info_wrap">
            <li class="doy_sale_info_item">
              <span class="doy_sale_info_item_title">{{$t('l.doy_sale_info_item_title1')}}</span>
              <span class="doy_sale_info_item_val">
                <countTo :endVal='DOYBalance/1' :duration='3000' :decimals="2"></countTo>
              </span>
            </li>
            <li class="doy_sale_info_item">
              <span class="doy_sale_info_item_title">{{$t('l.doy_sale_info_item_title2')}}</span>
              <span class="doy_sale_info_item_val"><input type="text" class="doy_sale_ipt" @input="input_num(1)" v-model="DOYAmount" :placeholder="$t('l.doy_ipt_place1')"></span>
            </li>
            <li class="doy_sale_info_item">
              <span class="doy_sale_info_item_title">{{$t('l.doy_title_2')}}</span>
              <span class="doy_sale_info_item_val"><input type="text" class="doy_sale_ipt" @input="input_num(2)" v-model="DOYPrice" :placeholder="$t('l.doy_ipt_place2')"></span>
            </li>
            <li class="doy_sale_info_item">
              <span class="doy_sale_info_item_title">{{$t('l.doy_sale_info_item_title3')}}</span>
              <span class="doy_sale_info_item_val">{{orderPrice}} {{$t('l.token3')}}</span>
            </li>
          </ul>
          <span>
            <div v-if="hasApproved" @click="handleCreateSaleOrder" class="edit_btn full_btn modal_btn">{{$t('l.doy_sale_nav')}}</div>
            <div v-else @click="handleApprovedFor" class="edit_btn full_btn modal_btn">{{$t('l.approve')}}</div>
          </span>
        </div>
      </Modal>
      <Modal class="battle_modal" v-model="showCreateBuyOrderModal" :footer="null" :width="!$store.state.accounts.isMobile ? '380px' : '88%'" @cancel="handleCreateBuyOrderModalCancel" :centered="true" :destroyOnClose="true" :zIndex="999">
        <div class="pools__dialog-inner pools__dialog-withdraw">
          <div class="pools__dialog__header">{{$t('l.buy_doy_btn')}}</div>
          <ul class="doy_sale_info_wrap">
            <li class="doy_sale_info_item">
              <span class="doy_sale_info_item_title">{{$t('l.balance')}}</span>
              <span class="doy_sale_info_item_val">
                <countTo :endVal='USDTBalance/1' :duration='3000' :decimals="2"></countTo> {{$t('l.token3')}}
              </span>
            </li>
            <li class="doy_sale_info_item">
              <span class="doy_sale_info_item_title">{{$t('l.doy_buy_info_item_title2')}}</span>
              <span class="doy_sale_info_item_val"><input type="text" class="doy_sale_ipt" @input="input_num(1)" v-model="DOYAmount" :placeholder="$t('l.doy_ipt_place1')"></span>
            </li>
            <li class="doy_sale_info_item">
              <span class="doy_sale_info_item_title">{{$t('l.doy_title_2')}}</span>
              <span class="doy_sale_info_item_val"><input type="text" class="doy_sale_ipt" @input="input_num(2)" v-model="DOYPrice" :placeholder="$t('l.doy_ipt_place2')"></span>
            </li>
            <li class="doy_sale_info_item">
              <span class="doy_sale_info_item_title">{{$t('l.doy_sale_info_item_title3')}}</span>
              <span class="doy_sale_info_item_val">{{orderPrice}} {{$t('l.token3')}}</span>
            </li>
          </ul>
          <span>
            <div v-if="hasApproved" @click="handleCreateBuyOrder" class="edit_btn full_btn modal_btn">{{$t('l.doy_buy_nav')}}</div>
            <div v-else @click="handleApprovedFor" class="edit_btn full_btn modal_btn">{{$t('l.approve')}}</div>
          </span>
        </div>
      </Modal>
      <Modal class="deposit_modal" v-model="showBuyModal" :footer="null" :width="!$store.state.accounts.isMobile ? '380px' : '88%'" @cancel="handleMCancel('1')" :centered="true">
        <div class="pools__dialog-inner pools__dialog-deposite">
          <ul class="pools__dialog__fields">
            <li class="pools__row-1">
              <div class="pools__logo-name">
                <img class="pools__coin-logo" src="../assets/doy.png">
                <div class="pools__coin-name">{{$t('l.token4')}}</div>
              </div>
            </li>
            <li class="pools__row pools_info_row">
              <div class="left_wrap">
                <p>{{$t('l.doy_title_1')}}</p>
                <p>0.01~{{(dataLists.length > 0 && currentTransIndex > -1) ? dataLists[currentTransIndex].amount : 0}}</p>
              </div>
              <div class="right_wrap">
                <p>{{$t('l.doy_title_2')}}</p>
                <p>{{(dataLists.length > 0 && currentTransIndex > -1) ? dataLists[currentTransIndex].price : 0}}{{$t('l.token3')}}</p>
              </div>
            </li>
            <li class="pools__row">
              <div class="pools__labe-field">{{$t('l.balance')}}({{$t('l.token3')}})</div>
              <div class="pools__label-value pools__label-value--black">
                <countTo :endVal='USDTBalance/1' :duration='3000' :decimals="2"></countTo>
              </div>
            </li>
            <li class="pools__dialog__input">
              <input @input="input_num(1)" :placeholder="$t('l.iptPlace')" v-model="DOYAmount">
              <button :disabled="USDTBalance <= 0" @click="handleBuyAll" class="g-button pools__dialog__deposit-all g-button-heco-theme  g-button--normal">{{$t('l.trans_all')}}</button>
            </li>
            <li class="pools__row">
              <div class="pools__labe-field">{{$t('l.need_to_pay')}}({{$t('l.token3')}})</div>
              <div class="pools__label-value pools__label-value--black">
                <countTo :endVal='(((this.DOYAmount ? this.DOYAmount / 1 : 0) * (((dataLists.length > 0 && currentTransIndex > -1) ? dataLists[currentTransIndex].price : 0) / 1)).toFixed(2)) / 1' :duration='1000' :decimals="2"></countTo>
              </div>
            </li>
            <li>
              <button :disabled="USDTBalance <= 0" @click="handleBuyOrder" class="g-button pools__dialog__option g-button-heco-theme deposit_btn">{{$t('l.doy_buy')}}{{$t('l.token4')}}</button>
            </li>
          </ul>
        </div>
      </Modal>
      <Modal class="deposit_modal" v-model="showSaleModal" :footer="null" :width="!$store.state.accounts.isMobile ? '380px' : '88%'" @cancel="handleMCancel('2')" :centered="true">
        <div class="pools__dialog-inner pools__dialog-deposite">
          <ul class="pools__dialog__fields">
            <li class="pools__row-1">
              <div class="pools__logo-name">
                <img class="pools__coin-logo" src="../assets/doy.png">
                <div class="pools__coin-name">{{$t('l.token4')}}</div>
              </div>
            </li>
            <li class="pools__row pools_info_row">
              <div class="left_wrap">
                <p>{{$t('l.doy_title_1')}}</p>
                <p>0.01~{{(dataLists.length > 0 && currentTransIndex > -1) ? dataLists[currentTransIndex].amount : 0}}</p>
              </div>
              <div class="right_wrap">
                <p>{{$t('l.doy_title_2')}}</p>
                <p>{{(dataLists.length > 0 && currentTransIndex > -1) ? dataLists[currentTransIndex].price : 0}}{{$t('l.token3')}}</p>
              </div>
            </li>
            <li class="pools__row">
              <div class="pools__labe-field">{{$t('l.balance')}}({{$t('l.token4')}})</div>
              <div class="pools__label-value pools__label-value--black">
                <countTo :endVal='DOYBalance/1' :duration='3000' :decimals="2"></countTo>
              </div>
            </li>
            <li class="pools__dialog__input">
              <input @input="input_num(1)" :placeholder="$t('l.iptPlace')" v-model="DOYAmount">
              <button :disabled="DOYBalance <= 0" @click="handleSaleAll" class="g-button pools__dialog__deposit-all g-button-heco-theme  g-button--normal">{{$t('l.trans_all')}}</button>
            </li>
            <li class="pools__row">
              <div class="pools__labe-field">{{$t('l.receive')}}({{$t('l.token3')}})</div>
              <div class="pools__label-value pools__label-value--black">
                <countTo :endVal='((0.8 * (this.DOYAmount ?  this.DOYAmount / 1 : 0) * (((dataLists.length > 0 && currentTransIndex > -1) ? dataLists[currentTransIndex].price : 0) / 1)).toFixed(2)) / 1' :duration='1000' :decimals="2"></countTo>
              </div>
            </li>
            <li>
              <button :disabled="DOYBalance <= 0" @click="handleSaleOrder" class="g-button pools__dialog__option g-button-heco-theme deposit_btn">{{$t('l.doy_sale')}}{{$t('l.token4')}}</button>
            </li>
          </ul>
        </div>
      </Modal>
    </Spin>
  </div>
</template>

<script>
import { Modal, Spin } from 'ant-design-vue'
import { ethers } from 'ethers';
import { mapGetters } from 'vuex'
import { batch, contract } from '@pooltogether/etherplex'
import countTo from 'vue-count-to';
export default {
  components: {
    Modal,
    Spin,
    countTo
  },
  computed: {
    ...mapGetters('accounts', ['getActiveAccount', 'emptyAddress', 'getProviderEthers', 'getDemicals', 'getDataUpdateTime', 'getIsMainChainID']),
    ...mapGetters('contracts', ['getMaxUint256', 'getDiamondAbi', 'getDiamondAddress', 'getUSDTAbi', 'getUSDTAddress', 'getC2CAbi', 'getC2CAddress'])
  },
  data() {
    return {
      spinStatus: false,
      currentHref: window.location.origin + window.location.pathname,
      navArr: ['doy_sale_nav', 'doy_buy_nav'],
      navArr_type1: ['doy_nav_type1_market', 'doy_nav_type1_my'],
      navArr_type2: ['doy_nav_type2_market', 'doy_nav_type2_my'],
      currentIndex: 0,
      currentTypeIndex: 0,
      currentTransIndex: -1,
      showCreateSaleOrderModal: false,
      showCreateBuyOrderModal: false,
      showBuyModal: false,
      showSaleModal: false,
      contractDiamond: null,
      contractUSDT: null,
      contractC2C: null,
      hasApproved: false,
      dataLists: [],
      targetJumpPage: 1,
      pageTotal: 0,
      currentPage: 1,
      pageSize: 8,
      DOYAmount: undefined,
      DOYPrice: undefined,
      DOYBalance: 0,
      USDTBalance: 0,
      orderPrice: 0,
      allMyOrdersIDArr: [],
      allOrdersIDArr: [],
      recentPrice: 0,
    }
  },
  methods: {
    handleCopyLink() {
      this.$copyText(this.currentHref + '#/doystore?address=' + this.getActiveAccount).then(() => {
        this.$message.success(this.$t('l.copy_success'))
      }, function () {
        this.$message.error(this.$t('l.copy_success'))
      })
    },
    async handleContChange(index) {
      this.currentIndex = index
      this.currentTypeIndex = 0
      this.targetJumpPage = 1
      this.currentPage = 1
      this.pageTotal = 0
      await this.getDataLists()
    },
    async handleContChangeType(index) {
      this.currentTypeIndex = index
      this.targetJumpPage = 1
      this.currentPage = 1
      this.pageTotal = 0
      await this.getDataLists()
    },
    async getDataLists() {
      this.dataLists = []
      if (this.currentTypeIndex == 0) {
        await this.getAllOrders()
      } else if (this.currentTypeIndex == 1) {
        await this.getCreateOrders()
      }
    },
    async getAllOrders() {
      if (this.getIsMainChainID) {
        if (this.getActiveAccount) {
          this.spinStatus = true
          this.dataLists = []
          this.allOrdersIDArr = []
          try {
            let signer = this.getProviderEthers.getSigner();
            this.contractC2C = new ethers.Contract(this.getC2CAddress, this.getC2CAbi, signer);
            if (this.currentIndex == 0) {
              this.allOrdersIDArr = (await this.contractC2C.getAllSellOrders()).map(val => parseInt(val))
            } else if (this.currentIndex == 1) {
              this.allOrdersIDArr = (await this.contractC2C.getAllBuyOrders()).map(val => parseInt(val))
            }
            this.recentPrice = (ethers.utils.formatUnits(await this.contractC2C.lastPrice(), this.getDemicals) / 1).toFixed(2)
            await this.getPagaDataLists()
            this.spinStatus = false
          } catch (error) {
            console.log(error)
            this.spinStatus = false
            this.$message.error(this.$t('l.catch_err'))
          }
        } else {
          this.$message.error(this.$t('l.error_tips_unconnect'))
        }
      } else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    async getCreateOrders() {
      if (this.getIsMainChainID) {
        if (this.getActiveAccount) {
          this.spinStatus = true
          this.dataLists = []
          this.allMyOrdersIDArr = []
          try {
            let signer = this.getProviderEthers.getSigner();
            this.contractC2C = new ethers.Contract(this.getC2CAddress, this.getC2CAbi, signer);
            if (this.currentIndex == 0) {
              this.allMyOrdersIDArr = (await this.contractC2C.getOrders(this.getActiveAccount)).map(val => parseInt(val))
            } else if (this.currentIndex == 1) {
              this.allMyOrdersIDArr = (await this.contractC2C.getBuyOrders(this.getActiveAccount)).map(val => parseInt(val))
            }
            this.recentPrice = (ethers.utils.formatUnits(await this.contractC2C.lastPrice(), this.getDemicals) / 1).toFixed(2)
            await this.getPagaDataLists()
            this.spinStatus = false
          } catch (error) {
            console.log(error)
            this.spinStatus = false
            this.$message.error(this.$t('l.catch_err'))
          }
        } else {
          this.$message.error(this.$t('l.error_tips_unconnect'))
        }
      } else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    async getPagaDataLists() {
      if (this.currentTypeIndex == 0) {
        this.pageTotal = this.$pageCount(this.allOrdersIDArr.length, this.pageSize)
        this.currentPage > this.pageTotal ? (this.currentPage = this.pageTotal) : 1
        let currentPageIds = this.$pagination(this.currentPage, this.pageSize, this.allOrdersIDArr)
        this.dataLists = await this.getOrderInfoByIds(currentPageIds)
      } else if (this.currentTypeIndex == 1) {
        this.pageTotal = this.$pageCount(this.allMyOrdersIDArr.length, this.pageSize)
        this.currentPage > this.pageTotal ? (this.currentPage = this.pageTotal) : 1
        let currentPageIds = this.$pagination(this.currentPage, this.pageSize, this.allMyOrdersIDArr)
        this.dataLists = await this.getOrderInfoByIds(currentPageIds)
      }
    },
    async getOrderInfoByIds(ids) {
      let dataLists = []
      this.spinStatus = true
      let provider = new ethers.providers.Web3Provider(window.ethereum)
      let contractOrders = []
      for (let i = 0; i < ids.length; i++) {
        contractOrders.push(contract('C2C' + i, this.getC2CAbi, this.getC2CAddress).orders(ids[i]))
      }
      let resultOrders = await batch(provider, ...contractOrders)
      for (let j = 0; j < ids.length; j++) {
        dataLists.push({
          orderId: ids[j],
          amount: ((ethers.utils.formatUnits(resultOrders['C2C' + j].orders.amount, this.getDemicals)) / 1).toFixed(2),
          price: ((ethers.utils.formatUnits(resultOrders['C2C' + j].orders.price, this.getDemicals)) / 1).toFixed(2),
          totalPrice: (((ethers.utils.formatUnits(resultOrders['C2C' + j].orders.amount, this.getDemicals)) / 1) * ((ethers.utils.formatUnits(resultOrders['C2C' + j].orders.price, this.getDemicals)) / 1)).toFixed(2)
        })
      }
      this.spinStatus = false
      return dataLists
    },
    async handleShowCreateSaleOrderModal() {
      if (this.getIsMainChainID) {
        if (this.getActiveAccount) {
          this.spinStatus = true
          try {
            let signer = this.getProviderEthers.getSigner();
            this.contractDiamond = new ethers.Contract(this.getDiamondAddress, this.getDiamondAbi, signer);
            this.DOYBalance = ethers.utils.formatUnits(await this.contractDiamond.balanceOf(this.getActiveAccount), this.getDemicals)
            this.spinStatus = false
            this.showCreateSaleOrderModal = true
          } catch (error) {
            this.spinStatus = false
            this.$message.error(this.$t('l.catch_err'))
          }
        } else {
          this.$message.error(this.$t('l.error_tips_unconnect'))
        }
      } else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    async handleShowCreateBuyOrderModal() {
      if (this.getIsMainChainID) {
        if (this.getActiveAccount) {
          this.spinStatus = true
          try {
            let signer = this.getProviderEthers.getSigner();
            this.contractUSDT = new ethers.Contract(this.getUSDTAddress, this.getUSDTAbi, signer);
            this.USDTBalance = ethers.utils.formatUnits(await this.contractUSDT.balanceOf(this.getActiveAccount), this.getDemicals)
            this.spinStatus = false
            this.showCreateBuyOrderModal = true
          } catch (error) {
            this.spinStatus = false
            this.$message.error(this.$t('l.catch_err'))
          }
        } else {
          this.$message.error(this.$t('l.error_tips_unconnect'))
        }
      } else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    handleCreateSaleOrderModalCancel() {
      this.showCreateSaleOrderModal = false
      this.DOYAmount = undefined
      this.DOYPrice = undefined
      this.orderPrice = 0
    },
    handleCreateBuyOrderModalCancel() {
      this.showCreateBuyOrderModal = false
      this.DOYAmount = undefined
      this.DOYPrice = undefined
      this.orderPrice = 0
    },
    async handleCreateSaleOrder() {
      if (this.getIsMainChainID) {
        if (this.getActiveAccount) {
          if (!(this.DOYAmount && this.DOYPrice && this.DOYAmount / 1 > 0 && this.DOYPrice / 1 > 0 && Number(this.DOYAmount / 1) && Number(this.DOYPrice / 1))) {
            this.$message.error(this.$t('l.error_doy_input'))
            return
          }
          if (this.DOYAmount / 1 > this.DOYBalance / 1) {
            this.$message.error(this.$t('l.insufficient_doy_input'))
            return
          }
          this.spinStatus = true
          try {
            let signer = this.getProviderEthers.getSigner();
            this.contractC2C = new ethers.Contract(this.getC2CAddress, this.getC2CAbi, signer);
            let doyPrice = '0x' + ((this.DOYPrice / 1) * 1e18).toString(16)
            let doyAmount = '0x' + ((this.DOYAmount / 1) * 1e18).toString(16)
            let inviteAddress = this.$getCookie('inviteAddress') ? ((this.$getCookie('inviteAddress') == this.getActiveAccount) ? '' : this.$getCookie('inviteAddress')) : ''
            let tx = await this.contractC2C.putSellOrder(doyPrice, doyAmount, inviteAddress ? inviteAddress : this.emptyAddress)
            await tx.wait()
            this.spinStatus = false
            this.$message.success(this.$t('l.operate_ok'))
            this.handleCreateSaleOrderModalCancel()
            await this.getDataLists()
          } catch (error) {
            this.spinStatus = false
            this.$message.error(this.$t('l.catch_err'))
          }
        } else {
          this.$message.error(this.$t('l.error_tips_unconnect'))
        }
      } else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    async handleCreateBuyOrder() {
      if (this.getIsMainChainID) {
        if (this.getActiveAccount) {
          if (!(this.DOYAmount && this.DOYPrice && this.DOYAmount / 1 > 0 && this.DOYPrice / 1 > 0 && Number(this.DOYAmount / 1) && Number(this.DOYPrice / 1))) {
            this.$message.error(this.$t('l.error_doy_input'))
            return
          }
          if (this.orderPrice / 1 > this.USDTBalance / 1) {
            this.$message.error(this.$t('l.insufficient_usdt_input'))
            return
          }
          this.spinStatus = true
          try {
            let signer = this.getProviderEthers.getSigner();
            this.contractC2C = new ethers.Contract(this.getC2CAddress, this.getC2CAbi, signer);
            let doyPrice = '0x' + ((this.DOYPrice / 1) * 1e18).toString(16)
            let doyAmount = '0x' + ((this.DOYAmount / 1) * 1e18).toString(16)
            let tx = await this.contractC2C.putBuyOrder(doyPrice, doyAmount)
            await tx.wait()
            this.spinStatus = false
            this.$message.success(this.$t('l.operate_ok'))
            this.handleCreateBuyOrderModalCancel()
            await this.getDataLists()
          } catch (error) {
            this.spinStatus = false
            this.$message.error(this.$t('l.catch_err'))
          }
        } else {
          this.$message.error(this.$t('l.error_tips_unconnect'))
        }
      } else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    async handleShowBuyOrderModal(index) {
      if (this.getIsMainChainID) {
        if (this.getActiveAccount) {
          this.spinStatus = true
          try {
            let signer = this.getProviderEthers.getSigner();
            this.contractUSDT = new ethers.Contract(this.getUSDTAddress, this.getUSDTAbi, signer);
            this.USDTBalance = ethers.utils.formatUnits(await this.contractUSDT.balanceOf(this.getActiveAccount), this.getDemicals)
            this.spinStatus = false
            this.currentTransIndex = index
            this.showBuyModal = true
          } catch (error) {
            this.spinStatus = false
            this.$message.error(this.$t('l.catch_err'))
          }
        } else {
          this.$message.error(this.$t('l.error_tips_unconnect'))
        }
      } else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    handleBuyAll() {
      this.DOYAmount = this.dataLists[this.currentTransIndex].amount
    },
    async handleBuyOrder() {
      if (this.getIsMainChainID) {
        if (this.getActiveAccount) {
          if (!(this.DOYAmount && this.DOYAmount / 1 > 0 && Number(this.DOYAmount / 1) && this.DOYAmount / 1 <= this.dataLists[this.currentTransIndex].amount)) {
            this.$message.error(this.$t('l.error_doy_input2'))
            return
          }
          let USDTNeed = ((this.DOYAmount ? this.DOYAmount / 1 : 0) * (((this.dataLists.length > 0 && this.currentTransIndex > -1) ? this.dataLists[this.currentTransIndex].price : 0) / 1)).toFixed(2)
          if (USDTNeed / 1 > this.USDTBalance / 1) {
            this.$message.error(this.$t('l.insufficient_usdt_input'))
            return
          }
          this.spinStatus = true
          try {
            let signer = this.getProviderEthers.getSigner();
            this.contractC2C = new ethers.Contract(this.getC2CAddress, this.getC2CAbi, signer);
            let doyAmount = '0x' + ((this.DOYAmount / 1) * 1e18).toString(16)
            let tx = await this.contractC2C.buy(this.dataLists[this.currentTransIndex].orderId,doyAmount)
            await tx.wait()
            this.spinStatus = false
            this.$message.success(this.$t('l.operate_ok'))
            this.handleMCancel('1')
            await this.getDataLists()
          } catch (error) {
            this.spinStatus = false
            await this.getDataLists()
            this.$message.error(this.$t('l.catch_err'))
          }
        } else {
          this.$message.error(this.$t('l.error_tips_unconnect'))
        }
      } else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    async handleShowSaleOrderModal(index) {
      if (this.getIsMainChainID) {
        if (this.getActiveAccount) {
          this.spinStatus = true
          try {
            let signer = this.getProviderEthers.getSigner();
            this.contractDiamond = new ethers.Contract(this.getDiamondAddress, this.getDiamondAbi, signer);
            this.DOYBalance = ethers.utils.formatUnits(await this.contractDiamond.balanceOf(this.getActiveAccount), this.getDemicals)
            this.spinStatus = false
            this.currentTransIndex = index
            this.showSaleModal = true
          } catch (error) {
            this.spinStatus = false
            this.$message.error(this.$t('l.catch_err'))
          }
        } else {
          this.$message.error(this.$t('l.error_tips_unconnect'))
        }
      } else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    handleSaleAll() {
      this.DOYAmount = this.dataLists[this.currentTransIndex].amount
    },
    async handleSaleOrder() {
      if (this.getIsMainChainID) {
        if (this.getActiveAccount) {
          if (!(this.DOYAmount && this.DOYAmount / 1 > 0 && Number(this.DOYAmount / 1) && this.DOYAmount / 1 <= this.dataLists[this.currentTransIndex].amount)) {
            this.$message.error(this.$t('l.error_doy_input2'))
            return
          }
          if (this.DOYBalance / 1 < this.DOYAmount / 1) {
            this.$message.error(this.$t('l.insufficient_doy_input'))
            return
          }
          this.spinStatus = true
          try {
            let signer = this.getProviderEthers.getSigner();
            this.contractC2C = new ethers.Contract(this.getC2CAddress, this.getC2CAbi, signer);
            let inviteAddress = this.$getCookie('inviteAddress') ? ((this.$getCookie('inviteAddress') == this.getActiveAccount) ? '' : this.$getCookie('inviteAddress')) : ''
            let doyAmount = '0x' + ((this.DOYAmount / 1) * 1e18).toString(16)
            let tx = await this.contractC2C.sell(this.dataLists[this.currentTransIndex].orderId, doyAmount,inviteAddress ? inviteAddress : this.emptyAddress)
            await tx.wait()
            this.spinStatus = false
            this.$message.success(this.$t('l.operate_ok'))
            this.handleMCancel('2')
            await this.getDataLists()
          } catch (error) {
            this.spinStatus = false
            await this.getDataLists()
            this.$message.error(this.$t('l.catch_err'))
          }
        } else {
          this.$message.error(this.$t('l.error_tips_unconnect'))
        }
      } else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    handleMCancel(type) {
      this.currentTransIndex = -1
      this.DOYAmount = undefined
      type == 1 ? (this.showBuyModal = false) : (this.showSaleModal = false)
    },
    async handleCancelOrder(id) {
      if (this.getIsMainChainID) {
        if (this.getActiveAccount) {
          this.spinStatus = true
          try {
            let signer = this.getProviderEthers.getSigner();
            this.contractC2C = new ethers.Contract(this.getC2CAddress, this.getC2CAbi, signer);
            let tx = await this.contractC2C.cancel(id)
            await tx.wait()
            this.spinStatus = false
            this.$message.success(this.$t('l.operate_ok'))
            await this.getDataLists()
          } catch (error) {
            this.spinStatus = false
            this.$message.error(this.$t('l.catch_err'))
          }
        } else {
          this.$message.error(this.$t('l.error_tips_unconnect'))
        }
      } else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    async handleJumpToTargetPage() {
      if (!this.targetJumpPage || !Number(this.targetJumpPage)) {
        return
      }
      if (parseInt(this.targetJumpPage) <= 0) {
        this.currentPage = 1
        this.targetJumpPage = 1
        return
      } else if (parseInt(this.targetJumpPage) >= this.pageTotal) {
        this.targetJumpPage = this.pageTotal
        if (this.currentPage == this.targetJumpPage) {
          return
        } else {
          this.currentPage = this.pageTotal
        }
      } else {
        this.currentPage = this.targetJumpPage
      }
      await this.getPagaDataLists()
    },
    async handlePageChange(type) {
      if (type == 'prev') {
        if (this.currentPage > 1) {
          this.currentPage--
        } else {
          return
        }
      } else if (type == 'next') {
        if (this.currentPage < this.pageTotal) {
          this.currentPage++
        } else {
          return
        }
      }
      this.targetJumpPage = this.currentPage
      await this.getPagaDataLists()
    },
    async handleApprovedFor() {
      if (this.getIsMainChainID) {
        try {
          if (!this.getProviderEthers || !this.getActiveAccount) {
            this.$message.error(this.$t('l.error_tips_unconnect'))
            return
          }
          this.spinStatus = true
          let tx1 = await this.contractDiamond.approve(this.getC2CAddress, this.getMaxUint256)
          let tx2 = await this.contractUSDT.approve(this.getC2CAddress, this.getMaxUint256)
          await tx1.wait()
          await tx2.wait()
          this.hasApproved = true
          this.spinStatus = false
          this.$message.success(this.$t('l.approve_ok'))
        } catch (error) {
          this.spinStatus = false
          this.$message.error(this.$t('l.catch_err'))
        }
      } else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    input_num(type) {
      if (type == 1) {
        this.DOYAmount = this.DOYAmount.replace(/[^\d.]/g, "")
        this.DOYAmount = this.DOYAmount.replace(/^\./g, "")
        this.DOYAmount = this.DOYAmount.replace(/\.{2,}/g, ".")
        this.DOYAmount = this.DOYAmount.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
        this.DOYAmount = this.DOYAmount.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      } else if (type == 2) {
        this.DOYPrice = this.DOYPrice.replace(/[^\d.]/g, "")
        this.DOYPrice = this.DOYPrice.replace(/^\./g, "")
        this.DOYPrice = this.DOYPrice.replace(/\.{2,}/g, ".")
        this.DOYPrice = this.DOYPrice.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
        this.DOYPrice = this.DOYPrice.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      }
      if (this.DOYAmount && this.DOYPrice && this.DOYAmount > 0 && this.DOYPrice && Number(this.DOYAmount / 1) && Number(this.DOYPrice / 1)) {
        this.orderPrice = ((this.DOYAmount / 1) * (this.DOYPrice / 1)).toFixed(2)
      }
    },
    async sleep(sleepTime) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve('success')
        }, sleepTime * 1000)
      })
    }
  },
  mounted() {
    let inviteAddress = this.$route.query.address ? this.$route.query.address : ''
    if (inviteAddress) {
      this.$setCookie('inviteAddress', inviteAddress, 30 * 24 * 60 * 60)
    }
    let timeInterval = setInterval(async () => {
      if (!this.getDiamondAbi || !this.getDiamondAddress || !this.getUSDTAbi || !this.getUSDTAddress || !this.getC2CAbi || !this.getC2CAddress) {
        this.$store.dispatch("contracts/storeDiamondAbi");
        this.$store.dispatch("contracts/storeDiamondAddress");
        this.$store.dispatch("contracts/storeUSDTAbi");
        this.$store.dispatch("contracts/storeUSDTAddress");
        this.$store.dispatch("contracts/storeC2CAbi");
        this.$store.dispatch("contracts/storeC2CAddress");
      } else {
        clearInterval(timeInterval)
        await this.getDataLists()
      }
    }, 500)
    this.getHasApprovedInterVal = setInterval(async () => {
      if (this.getDiamondAbi && this.getDiamondAddress) {
        if (this.getIsMainChainID) {
          if (this.getActiveAccount) {
            let signer = this.getProviderEthers.getSigner();
            this.contractDiamond = new ethers.Contract(this.getDiamondAddress, this.getDiamondAbi, signer);
            this.contractUSDT = new ethers.Contract(this.getUSDTAddress, this.getUSDTAbi, signer);
            //查询授权信息
            let approveAmountDOY = await this.contractDiamond.allowance(this.getActiveAccount, this.getC2CAddress)
            let approveAmountUSDT = await this.contractUSDT.allowance(this.getActiveAccount, this.getC2CAddress)
            if (approveAmountDOY._hex && approveAmountDOY._hex > 0 && approveAmountUSDT._hex && approveAmountUSDT._hex > 0) {
              this.hasApproved = true
              clearInterval(this.getHasApprovedInterVal)
            } else {
              this.hasApproved = false
            }
          }
        } else {
          this.$message.error(this.$t('l.no_main_tips'))
        }
      }
    }, 500)
  }
}
</script>

<style scoped>
.battle_wrap {
  padding: 16px;
}
.operate {
  max-width: 800px;
  margin: 20px auto 0;
}
.operate_btn_wrap {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.record_btn {
  margin-right: 8px;
  border-color: #52ccc5;
  color: #52ccc5;
}
.record_btn,
.create_btn {
  min-width: 80px;
  height: 32px;
}
.create_btn {
  background: linear-gradient(#8ae8e4, #52ccc5);
}
.choose_modal_wrap_root {
  width: 100%;
  overflow: hidden;
}
.monster_wrap {
  width: auto;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
}
.monster_item {
  width: 120px;
  min-width: 120px;
  /* height: 160px; */
  border-radius: 8px;
  margin-right: 8px;
  cursor: pointer;
  position: relative;
}
.monster_itemBox {
  width: 100%;
  height: 160px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
}
.monster_item .text_wrap {
  padding-top: 8px;
}
.nft_info .name {
  text-align: center;
}
.monster_item .text_wrap .num_title,
.monster_item .text_wrap .num_val {
  font-size: 12px;
  color: #cccccc;
}
.monster_choose_circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #888888;
  padding: 4px;
  position: absolute;
  top: 12px;
  left: 12px;
}
.monster_choose_little_cicle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.monster_choose_circle.active {
  border-color: #52ccc5;
}
.monster_choose_circle.active .monster_choose_little_cicle {
  background-color: #52ccc5;
}
.choose_monster_btn_wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}
.choose_monster_btn {
  width: 120px;
  max-width: 240px;
  height: 32px;
  border: 0;
  outline-style: none;
  border-radius: 16px;
  background: linear-gradient(#e4cd8a, #c59f52);
}

.vault_top_nav {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}
.vault_top_nav_all {
  margin-bottom: 16px;
  flex-direction: column;
}
.vault_top_nav_all .copy_link {
  max-width: 500px;
  margin-top: 8px;
}
#shadowBox {
  width: 50%;
  height: 100%;
  position: absolute;
  z-index: 1;
  background-color: #52ccc5;
  border-radius: 100px;
  transition: 0.5s;
}
.vault_top_nav_type #shadowBox {
  background-color: transparent;
  border: 1px solid #52ccc5;
}
.vault_nav {
  position: relative;
  background-color: #000;
  width: 200px;
  max-width: 200px;
  height: 32px;
  line-height: 32px;
  border-radius: 20px;
  font-size: 14px;
  color: #888888;
  overflow: hidden;
  list-style: none;
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 0;
}
.valut_nav_item {
  flex: 1;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: 0.5s;
  text-align: center;
}
.valut_nav_item.active {
  color: #fff;
  border-radius: 16px;
}
.vault_top_nav_type .valut_nav_item.active {
  color: #52ccc5;
}
li {
  list-style: none;
}
.fight_title {
  width: 100%;
  max-width: 800px;
  padding: 0 12px;
  display: flex;
  flex-wrap: nowrap;
  color: #888888;
  font-size: 12px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}
.fight_title_item:nth-child(1) {
  flex: 1;
}
.fight_title_item:nth-child(2) {
  flex: 1;
}
.fight_title_item:nth-child(3) {
  flex: 1;
}
.fight_title_item:nth-child(4) {
  flex: 1;
}
.fight_title .fight_title_item:last-child {
  text-align: center;
}
.card_wrap {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.card_item {
  width: 100%;
  padding: 8px 12px;
  display: flex;
  flex-wrap: nowrap;
  font-size: 14px;
  color: #888888;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.12);
  border-radius: 4px;
  margin-top: 8px;
}
.card_item p,
.card_item div {
  margin: 0;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.card_item p:nth-child(1) {
  flex: 1;
}
.card_item p:nth-child(2) {
  color: #ccc;
  font-size: 16px;
  flex: 1;
}
.card_item p:nth-child(3) {
  color: #c59f52;
  font-size: 16px;
  flex: 1;
}
.card_item p:nth-child(4) {
  flex: 1;
}
.fight_btn {
  height: 32px;
  background: linear-gradient(#8ae8e4, #52ccc5);
}
.fight_ghost_btn {
  background: none;
  border: 1px solid #52ccc5;
  color: #52ccc5;
  font-weight: bolder;
}
.fight_info {
  width: 100%;
  max-width: 800px;
  padding: 24px 16px;
  background-color: #000;
  border-radius: 8px;
  color: #888;
  font-size: 14px;
  margin: 24px auto 0;
  border: 1px solid #52ccc5;
}
.fight_info_title {
  text-align: center;
  font-size: 24px;
  color: #c59f52;
  font-weight: bolder;
  margin-bottom: 16px;
}
.fight_info_desc {
  text-align: justify;
}
.pools__dialog__header {
  font-size: 20px;
  color: #cccccc;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  position: relative;
}
.choose_nft_wrap,
.player1_choosed_monster_wrap {
  width: 295px;
  height: 390px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #c59f52;
  font-size: 18px;
  font-weight: bolder;
  cursor: pointer;
  margin: 0 auto;
}
.choose_default_img {
  width: 295px;
  height: auto;
}
.evaluted_monster_wrap {
  padding: 0 8% !important;
  height: auto;
}
.player1_choosed_monster_wrap {
  padding: 24px;
}
.player1_choosed_monster_wrap img {
  width: 100%;
  height: 100%;
}
.add_text {
  margin-top: 8px;
  position: absolute;
}
.choose_amount_wrap {
  margin-top: 16px;
}
.player2_choose_amount_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.player2_choose_amount_wrap > .amount_title {
  margin-bottom: 0;
  color: #888888;
  font-size: 14px;
}
.player2_choose_amount_wrap > .amount_val {
  font-size: 20px;
  color: #c59f52;
  font-weight: bolder;
}
.battle_pass_wrap {
  width: 100%;
  max-width: 295px;
  margin: 16px auto 0;
}
.battle_pass_word_ipt {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.12);
  border-radius: 4px;
  border: 1px solid #c59f52;
  padding: 10px 12px;
  text-align: right;
  color: #666666;
  font-size: 14px;
  outline: none;
}
.battle_pass_word_ipt::placeholder {
  color: #666666;
}
.battle_tips {
  width: 100%;
  max-width: 295px;
  color: #888888;
  display: flex;
  justify-content: space-between;
  margin: 10px auto;
}
.battle_tips_vs {
  max-width: 100%;
}
.battle_tips span:last-child {
  color: #cccccc;
  font-size: 14px;
}
.modal_btn {
  max-width: 295px;
  margin: 0 auto;
}
.join_confirm_btn {
  margin-top: 16px;
}
.player2_choose_wrap {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: 16px;
}
.player1_monster_wrap,
.player2_monster_wrap {
  flex: 1;
  max-width: 280px;
}
.player2_monster_wrap .choose_nft_wrap {
  height: calc(100% - 26px);
  margin-bottom: 0;
  border-radius: 8px;
  box-shadow: 0 0 2px #c59f52;
  font-size: 14px;
}
.player1_monster_title {
  text-align: center;
  color: #888;
  font-size: 14px;
  margin-bottom: 8px;
}
.player1_monster_img {
  width: 100%;
  height: calc(100% - 26px);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #c59f52;
  font-weight: bolder;
}
.player1_monster_img img {
  width: 100%;
}
.player1_monster_img .text_wrap {
  padding-top: 8px;
}
.player1_monster_img p {
  margin-bottom: 0;
}
.player1_monster_img .num_title {
  color: #7192b0;
}
.player1_monster_img .num_val {
  color: #131d32;
}
.player1_monster_wrap img {
  width: 100%;
  height: 100%;
}
.vs_text {
  font-size: 24px;
  color: #cccccc;
  font-weight: bolder;
  display: flex;
  align-items: center;
  margin: 40px 10px 0;
}
.rank_title_wrap {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  padding: 0 16px;
}
.rank_title_item {
  flex: 1;
  color: #888888;
  font-size: 12px;
}
.rank_title_item:nth-child(2) {
  flex: 2;
}
.rank_info_wrap {
  width: 100%;
}
.rank_info_item {
  display: flex;
  height: 44px;
  padding: 0 16px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.12);
  margin-top: 8px;
  align-items: center;
  position: relative;
}
.rank_info_item span {
  flex: 1;
  font-weight: bolder;
}
.rank_info_item span:nth-child(1) {
  color: #888888;
  font-size: 14px;
}
.rank_info_item span:nth-child(2) {
  flex: 2;
  color: #c59f52;
  font-size: 16px;
}
.rank_info_item span:nth-child(3) {
  font-size: 14px;
}
.rank_info_item span:nth-child(3).win {
  color: #52ccc5;
}
.rank_info_item span:nth-child(3).draw {
  color: #7192b0;
}
.rank_info_item span:nth-child(3).lose {
  color: #bb0000;
}
.more_icon {
  position: absolute;
  right: 16px;
  font-size: 16px;
  color: #c59f52;
}
.fight_wrap {
  position: fixed;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  transition: all 0.5s linear;
  transform: translateY(-200vh);
  z-index: 9999;
}
.fight_wrap.show {
  opacity: 1;
  transform: translateY(-56px);
}
.doy_price_wrap {
  width: 100%;
  max-width: 800px;
  height: 40px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.12);
  border-radius: 20px;
  padding: 0 12px;
  margin: 16px auto;
}
.doy_price_wrap p {
  margin: 0;
  font-size: 14px;
  color: #cccccc;
}
.doy_price_wrap p:last-child {
  font-size: 16px;
  color: #c59f52;
  font-weight: bolder;
}
.doy_sale_info_wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.doy_sale_info_item {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.doy_sale_info_item_title {
  font-size: 14px;
  color: #888888;
}
.doy_sale_info_item_val {
  font-size: 16px;
  color: #cccccc;
}
.doy_sale_info_wrap .doy_sale_info_item:nth-child(1),
.doy_sale_info_wrap .doy_sale_info_item:nth-child(3) {
  margin-bottom: 16px;
}
.doy_sale_info_wrap .doy_sale_info_item:nth-child(2) {
  margin-bottom: 8px;
}
.doy_sale_info_wrap .doy_sale_info_item:nth-child(4) {
  margin-bottom: 24px;
}
.doy_sale_info_wrap .doy_sale_info_item:nth-child(4) .doy_sale_info_item_val {
  color: #c59f52;
}
.doy_sale_ipt {
  width: 100%;
  padding: 8px 12px;
  outline: none;
  background-color: rgba(255, 255, 255, 0.12);
  border-radius: 4px;
  border: 1px solid #c59f52;
  font-size: 14px;
  color: #666666;
  text-align: right;
}
.doy_sale_ipt::placeholder {
  font-size: 14px;
  color: #666666;
}
.copy_link {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: #000;
  padding: 0 8px;
}
.link_title {
  font-size: 14px;
  color: #888888;
  white-space: nowrap;
  margin-right: 8px;
}
.link_wrap {
  width: 100%;
  height: 28px;
  background-color: #1f1f1f;
  border-radius: 14px;
  position: relative;
  padding: 0 32px 0 7px;
  overflow: hidden;
}
.link_val {
  width: 100%;
  max-width: 100%;
  display: inline-block;
  height: 100%;
  line-height: 28px;
  font-size: 12px;
  color: #888888;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.copy_icon {
  position: absolute;
  right: 7px;
  top: 2px;
}
.btn_type_wrap div {
  width: 100%;
}
.pools__row,
.pools__row-1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pools__row {
  flex-wrap: wrap;
}
.pools__logo-name {
  display: flex;
  align-items: center;
  position: relative;
}
.pools__coin-logo {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.logo_lp_2 {
  position: absolute;
  top: 0;
  left: 10%;
}
.pools__coin-name {
  margin-left: 12px;
  font-size: 24px;
  color: #cccccc;
  font-weight: bolder;
}
.name_lp_2 {
  margin-left: 40px;
}
.pools__info,
.pools__labe-field {
  color: #888888;
  display: flex;
  align-items: center;
}
.pools__info {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  font-family: MicrosoftYaHei;
  margin-top: 4px;
}
.pools__apy {
  align-items: flex-end;
}
.pools__info,
.pools__labe-field {
  color: #888888;
  display: flex;
  align-items: center;
}
.pools__labe-field,
.ques__labe-field {
  color: #888888;
  display: flex;
  align-items: center;
}
.pools__apy-value {
  font-size: 24px;
  color: #00b595;
  font-weight: 700;
  font-family: DINPro-Black;
}
.pools__label-value {
  font-size: 14px;
  color: #cccccc;
  font-weight: bolder;
  margin-left: auto;
}
.pools__label-value--black {
  color: #cccccc;
}
.pools__button-group {
  display: flex;
  justify-content: space-between;
}
.pools__button-group .g-button {
  width: 46%;
}
.g-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  outline: none;
  border: none;
  background-color: rgb(81, 204, 197);
  border-radius: 22px;
  font-size: 14px;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
  border-radius: 8px;
}
.g-button--normal {
  border: 1px solid #52ccc5;
  color: #52ccc5;
  background: none;
  border-radius: 8px;
}
.pools__mao-logo__wrap {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  overflow: hidden;
  border-radius: 16px;
}
.pools__mao-logo {
  -webkit-filter: blur(50px);
  filter: blur(50px);
  opacity: 0.3;
}
.pools__dialog-inner {
  box-sizing: border-box;
  width: 100%;
  min-width: 100%;
  border-radius: 16px;
  font-size: 14px;
}
.pools__dialog__header {
  font-size: 24px;
  color: #cccccc;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 28px;
  position: relative;
}
.pools__income-field {
  background: #1f1f1f;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 32px;
}
.pools__income-button {
  width: 108px;
}
.pools__dialog__withdraw-field span:first-child {
  color: #888888;
}
.pools__dialog__withdraw-field span:last-child {
  font-size: 16px;
  color: #cccccc;
  float: right;
}
.pools__dialog__input input {
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
  padding: 15px 100px 15px 15px;
  outline: none;
  background-color: rgba(255, 255, 255, 0.12);
  border-radius: 4px;
  border: 1px solid #c59f52;
  color: #666666;
}
.g-button:disabled {
  opacity: 0.5;
}
.pools__dialog__input {
  position: relative;
}
.pools__dialog__deposit-all {
  position: absolute;
  right: 20px;
  top: 10px;
  border-radius: 4px;
  border: none;
  width: auto;
  padding: 0 10px;
  height: 32px;
  font-size: 14px;
  color: #fff;
  background: linear-gradient(#e4cd8a, #c59f52);
}
.pools__income-text {
  font-family: DINPro-Bold;
  font-size: 16px;
  color: #888888;
  letter-spacing: 0;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 6px;
}
.pools__income-button .g-button {
  height: 36px;
}
.pools__dialog__fields li {
  margin-bottom: 12px;
}
.pools__income-title {
  color: #888888;
}
.pools_info_row {
  background-color: rgba(255, 255, 255, 0.12);
  border-radius: 4px;
  font-size: 14px;
  padding: 6px 15px;
  color: #666666;
}
.pools_info_row p {
  margin-bottom: 0;
}
.left_wrap {
  text-align: left;
}
.right_wrap {
  text-align: right;
}
.deposit_btn {
  color: #fff;
  background: linear-gradient(#E4CD8A,#C59F52);
  border-radius: 4px;
}
@media (max-width: 768px) {
  .operate_btn_wrap {
    margin-top: 0;
  }
  .page_operate_btn_wrap {
    margin-top: 16px !important;
  }
}
</style>

<style>
.choose_nft_modal .ant-drawer-header {
  text-align: center;
  border-bottom: 0;
  background-color: #000;
}
.choose_nft_modal .ant-drawer-title {
  font-size: 20px;
  color: #cccccc;
  font-weight: bolder;
}
.choose_nft_modal .anticon {
  color: #cccccc;
}
.choose_nft_modal .ant-drawer-content {
  background-color: #000;
}
.choose_nft_modal .ant-drawer-body {
  padding: 16px 8px;
}
.battle_modal .ant-modal {
  overflow: hidden;
}
.battle_modal .ant-modal-content {
  height: 100%;
  overflow-y: scroll;
  background-color: #000;
  border: 1px solid #c59f52;
  border-radius: 8px !important;
}
.battle_modal .ant-modal-close {
  color: #cccccc;
  font-size: 24px;
}
.battle_modal .ant-modal-close:hover {
  color: #cccccc;
}
.battle_modal .ant-modal-body {
  padding: 24px 16px;
}
</style>